// ProfileInfoBadges.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import Badge from '../component/Badge.jsx';
import UserLevelBadge from '../container/UserLevelBadge.js';
import { UserLevelBadgeVersion } from '../component/UserLevelBadge.jsx';

import getLeaderboardBadgeData from '../resource/getLeaderboardBadgeData.js';
import leaderboardIcons from '../resource/getLeaderboardIcons.js';
import {
  RANGE_24H,
  RANGE_7D,
  RANGE_30D,
} from '../resource/leaderboardConstants.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import { MediaAssetFormat, getBadge } from '../resource/getMediaAsset.js';
import getTranslationKey from '../resource/getTranslationKey.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { ButtonId } from '../resource/mixpanel.js';
import {
  isUserLevelBadge,
  isCampaignBadge,
  USER_LEVEL_BADGE_PREFIX,
} from '../selector/getUserBadges.js';

import { color } from '../style/variables.js';
import media from '../style/media.js';

const defaultFunction = () => null;
const defaultArray = [];

export const ProfileInfoBadges = ({
  isImageOnly = false,
  badges = defaultArray,
  t,
  mixpanelModalId = '',
  publicBadgeUrlPath = '',
  publicLeaderboardUrlPath = '',
  onClick = defaultFunction,
}) => {
  return (
    <StyledProfileInfoBadge>
      {badges.map(badge => {
        const { isLeaderboardBadge, region, category, range, place } =
          getLeaderboardBadgeData({ badgeName: badge });
        if (isLeaderboardBadge) {
          const iconSource = getResourceUrl({
            path: publicLeaderboardUrlPath,
            endpoint: `/${category}.svg`,
            resourceType: PUBLIC,
          });
          const [avifSrcSet, webpSrcSet, jpgSrcSet] = [
            MediaAssetFormat.AVIF,
            MediaAssetFormat.WEBP,
            MediaAssetFormat.JPG,
          ].map(format =>
            getPublicSrcSet({ href: iconSource, size: 64, format })
          );
          const placeIcon = leaderboardIcons.place[place];
          return (
            <BadgeWrapper
              as={Link}
              key={badge}
              $isImageOnly={isImageOnly}
              onClick={event => onClick(event, badge)}
              to={`/leaderboard/${region}/${category}/${range}`}
              data-element_id={ButtonId.All.ButtonUserBadge}
              data-tracking_payload={{
                badge,
                modalId: mixpanelModalId,
              }}
            >
              <Badge
                isImageOnly={isImageOnly}
                icon={
                  <LeaderboardBadgeIcon
                    isImageOnly={isImageOnly}
                    range={range}
                    alt={`${category} icon`}
                    aria-hidden
                  >
                    <source {...avifSrcSet} type="image/avif" />
                    <source {...webpSrcSet} type="image/webp" />
                    <img {...jpgSrcSet} type="image/jpg" />
                  </LeaderboardBadgeIcon>
                }
                title={
                  <LeaderboardPlaceWrapper>
                    {t(`leaderboard_${region}`, {
                      ns: TranslationNamespace.PROFILE,
                    })}
                    <PlaceNoText>No.</PlaceNoText>
                    {place}
                    {placeIcon && (
                      <PlaceIconWrapper>
                        <img src={placeIcon} aria-hidden />
                      </PlaceIconWrapper>
                    )}
                  </LeaderboardPlaceWrapper>
                }
              />
            </BadgeWrapper>
          );
        }
        if (isUserLevelBadge({ badge })) {
          const level = +badge.replace(USER_LEVEL_BADGE_PREFIX, '');
          if (!isImageOnly) return null;
          return (
            <BadgeWrapper
              key={badge}
              $isImageOnly={isImageOnly}
              onClick={event => onClick(event, badge)}
              data-element_id={ButtonId.All.ButtonUserBadge}
              data-tracking_payload={{
                badge,
                modalId: mixpanelModalId,
              }}
            >
              <MobileAndTabletWrapper>
                <UserLevelBadge
                  level={level}
                  size={40}
                  version={UserLevelBadgeVersion.normal}
                />
              </MobileAndTabletWrapper>
              <DesktopWrapper>
                <UserLevelBadge
                  level={level}
                  size={44}
                  version={UserLevelBadgeVersion.normal}
                />
              </DesktopWrapper>
            </BadgeWrapper>
          );
        }
        if (isCampaignBadge({ badge })) {
          if (!isImageOnly) return null;
          return (
            <BadgeWrapper
              key={badge}
              $isImageOnly={isImageOnly}
              onClick={event => onClick(event, badge)}
              data-element_id={ButtonId.All.ButtonUserBadge}
              data-tracking_payload={{
                badge,
                modalId: mixpanelModalId,
              }}
            >
              <Badge
                isImageOnly={isImageOnly}
                icon={
                  getBadge({
                    badge,
                    size: 64,
                    format: MediaAssetFormat.PNG,
                    path: publicBadgeUrlPath,
                  }).href
                }
                title={
                  <BadgeTitleWrapper>
                    {t(getTranslationKey({ key: `badge_${badge}` }), {
                      ns: TranslationNamespace.LIVESTREAM,
                    })}
                  </BadgeTitleWrapper>
                }
              />
            </BadgeWrapper>
          );
        }
        return (
          <BadgeWrapper
            as={Link}
            key={badge}
            $isImageOnly={isImageOnly}
            onClick={event => onClick(event, badge)}
            to={`/story-categories/discover_${badge}`}
            data-element_id={ButtonId.All.ButtonUserBadge}
            data-tracking_payload={{
              badge,
              modalId: mixpanelModalId,
            }}
          >
            <Badge
              isImageOnly={isImageOnly}
              icon={
                getResourceUrl({
                  path: publicBadgeUrlPath,
                  endpoint: `/${badge}.svg`,
                  resourceType: PUBLIC,
                }).href
              }
              title={
                <BadgeTitleWrapper>
                  {t(badge, {
                    ns: TranslationNamespace.PROFILE,
                  })}
                </BadgeTitleWrapper>
              }
            />
          </BadgeWrapper>
        );
      })}
    </StyledProfileInfoBadge>
  );
};

ProfileInfoBadges.propTypes = {
  badges: PropTypes.array,
  mixpanelModalId: PropTypes.string,
  publicBadgeUrlPath: PropTypes.string,
  publicLeaderboardUrlPath: PropTypes.string,
  levelBadgePattern: PropTypes.string,
  isImageOnly: PropTypes.bool,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

const StyledProfileInfoBadge = styled.div``;

const BadgeWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
  ${({ $isImageOnly }) =>
    $isImageOnly
      ? css`
          margin-right: 16px;
          :last-child {
            margin-right: 0px;
          }
        `
      : css`
          margin-right: 4px;
          margin-bottom: 6px;
        `};
`;

const BadgeTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LeaderboardBadgeIcon = styled.picture`
  display: inline-block;
  border-radius: 50%;
  padding: ${({ isImageOnly }) => (isImageOnly ? 8 : 2)}px;
  width: ${({ isImageOnly }) => (isImageOnly ? 44 : 24)}px;
  height: ${({ isImageOnly }) => (isImageOnly ? 44 : 24)}px;
  ${({ range }) => {
    if (range === RANGE_24H) {
      return `background-color: ${color.peach};`;
    } else if (range === RANGE_7D) {
      return `background-color: ${color.green};`;
    } else if (range === RANGE_30D) {
      return `background-color: ${color.sky};`;
    }
  }}
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    vertical-align: super;
  }

  ${media.tablet`
    width: ${({ isImageOnly }) => (isImageOnly ? 40 : 18)}px;
    height: ${({ isImageOnly }) => (isImageOnly ? 40 : 18)}px;
  `};
`;

const LeaderboardPlaceWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 1.5;
  font-weight: 600;
  font-size: 14px;
  ${media.tablet`
    font-size: 12px;
  `};
`;

const PlaceAlignment = css`
  height: 21px; // line-height: 1.5 * font-size: 14px;
  display: inline-flex;
  align-items: flex-end;
  ${media.tablet`
    height: 18px; // line-height: 1.5 * font-size: 12px;
  `};
`;

const PlaceNoText = styled.span`
  ${PlaceAlignment};
  margin-left: 2px;
  margin-right: 2px;
  font-size: 10px;
`;

const PlaceIconWrapper = styled.div`
  ${PlaceAlignment};
  margin-bottom: 1px;
  > img {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
`;

const MobileAndTabletWrapper = styled.div`
  border-radius: 4px;
  padding: 4px;
  display: none;
  font-size: 0px;
  background-color: #f6f6f6;
  ${media.tablet`
    display: inline-block;
  `};
`;

const DesktopWrapper = styled.div`
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  background-color: #f6f6f6;
  font-size: 0px;
  ${media.tablet`
    display: none;
  `};
`;

export default withTranslation()(ProfileInfoBadges);
