// UserLevelBadge.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import { NULL_FUNCTION } from '../resource/defaults.js';

export const UserLevelBadgeVersion = {
  simple: 'simple',
  normal: 'normal',
  complex: 'complex',
  complex_bnw: 'complex_bnw',
};

export const UserLevelBadge = ({
  size = null,
  version = UserLevelBadgeVersion.simple,
  level = null,
  margin = '',
  shouldDirectToInformation = false,
  shouldShowLevelZero = false,
  publicBadgeUrlPath = '',
  directToInfomation = NULL_FUNCTION,
  showPointer = false,
}) => {
  if (typeof level !== 'number' || level < 0) return null;
  if (!shouldShowLevelZero && level <= 0) return null;

  const fileType = [
    UserLevelBadgeVersion.complex,
    UserLevelBadgeVersion.complex_bnw,
  ].includes(version)
    ? 'png'
    : 'svg';
  return (
    <StyledUserLevelBadge isLink={shouldDirectToInformation || showPointer}>
      <Badge
        src={
          getResourceUrl({
            path: publicBadgeUrlPath,
            endpoint: `/level_badge_${version}_${level}.${fileType}`,
            resourceType: PUBLIC,
          }).href
        }
        $size={size}
        $margin={margin}
        alt={`Level ${level}`}
        onClick={() => {
          if (shouldDirectToInformation) directToInfomation();
        }}
      />
    </StyledUserLevelBadge>
  );
};

UserLevelBadge.propTypes = {
  size: PropTypes.number,
  margin: PropTypes.string,
  level: PropTypes.number,
  shouldDirectToInformation: PropTypes.bool,
  shouldShowLevelZero: PropTypes.bool,
  showPointer: PropTypes.bool,
  version: PropTypes.oneOf(['simple', 'normal', 'complex', 'complex_bnw']),
  publicBadgeUrlPath: PropTypes.string,
  directToInfomation: PropTypes.func,
};

const StyledUserLevelBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'auto')};
`;

const Badge = styled.img`
  height: ${({ $size }) => ($size ? `${$size}px` : 'auto')};
  width: ${({ $size }) => ($size ? `${$size}px` : 'auto')};
  margin: ${({ $margin }) => $margin};
`;

export default UserLevelBadge;
